import React, { useState } from 'react'
import styled from 'styled-components'
import { FormButton, Image, SelectBox, Text } from '../../components/GlobalStyles/PageStyles'
import RoomIMG from "../../assets/hotel.png";
import { useNavigate } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { detailsSettings } from '../../utils/carouselSettings';
import { toast } from 'react-toastify';
import DiscountRibbon from '../../components/Utils/DiscountBanner';
import moment from 'moment';
import { GET_NOBEDS_ROOM_MIN_MAX_PRICES } from '../../graphql/queries/roomQueries';
import { useQuery } from '@apollo/client';

const CardContainer = styled.div`
    margin-top: 20px;
    background: #ebf4fd;
    padding: 16px;
    border-radius: 6px;
`

const Details = styled.div`
    width: 40%;
    margin-left: 20px
`

const Features = styled.div`
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    p{
        text-align: center;
        padding: 6px 10px;
        border: 1px solid #cbcbcb;
        border-radius: 20px;
        margin: 0
    }

    @media(max-width: 600px) {
        margin-top: 20px;
    }
}
`

const SliderContainer = styled.div`
    width: 60%;
    padding: 16px
`

const Flex = styled.div`

    display: flex;
    width: 100%;

    @media(max-width: 600px) {
        flex-direction: column
    }

    .img-container {
        @media(max-width: 600px) {
            width: 100% !important;
            margin: 0 0 16px 0;
        }
    }

    .details {
        @media(max-width: 600px) {
            width: 100% !important;
            margin: 0 0 16px 0 !important;
        }
    }
`

const RoomDetails = (props) => {

    const { room, roomNumbers, params } = props
    // const ratings = !room.ratings ? 0.00 : room.ratings
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user'))
    const [roomsNum, setroomsNum] = useState(1)

    const fromDate = moment(params.from).format('yyyy-MM-DDT06:00:00.000[Z]');
    const toDate = moment(params.from).add(1, 'days').format('yyyy-MM-DDT06:00:00.000[Z]');

    const { data: priceData, loading: priceLoading, error: priceError } = useQuery(GET_NOBEDS_ROOM_MIN_MAX_PRICES, {
        variables: {
            id: room.id,
            from:fromDate,
            to: toDate,
        }
    })

    const handleBook = () => {
        if (roomNumbers.length > 0) {
            let nums = [];
            let i = 0;
            while (i < roomsNum) {
                nums.push(roomNumbers[i]);
                i++;
            }


            if(user === null){
                navigate('/login')
            }else{
                const bookingData = {
                    from: params.from,
                    to: params.to,
                    roomNumbers: nums,
                    bookedBy: user.id,
                    paid: false,
                    price: priceData.getNobedsRoomMinMaxPrices.min,
                    amount: priceData.getNobedsRoomMinMaxPrices.min * nums.length,
                    people: params.people,
                    room: room.id,
                    hotel: room.hotel.id
                }
                navigate(`/payment/${room.hotel.id}/${room.id}/1`, { state: bookingData })
            }
        }
        else {
            toast.error("No hay habitaciones disponibles.", {
                autoClose: 5500,
                pauseOnHover: true
            })
        }
    }

    return (
        <CardContainer style={{ marginTop: '20px' }}>
            <Flex>
                {room.images.length === 0 ? (
                    <Image style={{ backgroundImage: `url(${RoomIMG})`, height: "300px", width: "60%", }} className="img-container" />
                ) : (
                    <SliderContainer className="img-container">
                        <Slider {...detailsSettings}>
                            {room.images.map(img => (
                                <div className="banners" key={img.uuid}>
                                    <img src={img.url} alt="" />
                                </div>
                            ))}
                        </Slider>
                    </SliderContainer>
                )}

                <Details style={{ width: '40%', marginLeft: '20px', position: 'relative' }} className="details">
                    {room.hotel.discount > 0 && (
                        <DiscountRibbon>
                            {`${room.hotel.discount}% de descuento`}
                        </DiscountRibbon>
                    )}
                    <Text className="clip">{room.name}</Text>
                    <Text className="clamp small" style={{ marginTop: '12px' }}>{room.description}</Text>
                    { priceLoading ? (
                        <Text className="small">
                            <span style={{ color: '#6c757d' }}>Cargando...</span>
                        </Text>
                    ) : ( 
                        priceData.getNobedsRoomMinMaxPrices.discount > 0 ? (
                            <>
                                <Text className="small">Desde: <span style={{ color:'#6c757d', textDecoration: 'line-through' }}>${priceData?.getNobedsRoomMinMaxPrices?.price} MXN<b style={{ color: "#ff5c5c" }}>*</b></span></Text>
                                <Text className="small">Ahora Desde: <span>${priceData?.getNobedsRoomMinMaxPrices?.min} MXN<b style={{ color: "#ff5c5c" }}>*</b></span></Text>
                            </>
                        ) : ( 
                            <Text className="small">Desde: ${priceData?.getNobedsRoomMinMaxPrices?.price} MXN<b style={{ color: "#ff5c5c" }}>*</b></Text>
                        )
                    )}
                    <Text className="small" style={{ fontSize: '12px' }}><b style={{ color: "#ff5c5c" }}>*</b>Recuerda que los precios pueden cambiar según el día o la temporada.</Text>
                    {roomNumbers.length !== 0 ? (
                        <SelectBox name="cars" id="cars" style={{ marginRight: '16px' }}
                            onChange={(e) => setroomsNum(Number(e.target.value))}
                            value={roomsNum}>
                            {roomNumbers.map((r, i) => (
                                <option value={i + 1}
                                >{`${i === 0 ? '1 Habitación' : `${i + 1} Habitaciones`} `}
                                </option>
                            ))}
                        </SelectBox>
                    ) : null}
                    {params['view'] === undefined ? (
                        <FormButton onClick={handleBook} disabled={priceLoading}
                            style={{ display: 'initial' }}>
                            Reservar
                        </FormButton>
                    ) : null}
                </Details>
            </Flex>
            <Features>
                {room.others.map(oth => (
                    <Text style={{fontSize: '12px'}} className="small">{oth}</Text>
                ))}
            </Features>

        </CardContainer>
    )
}

export default RoomDetails
