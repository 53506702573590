import React from 'react'
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from '@firebase/storage'
import moment from 'moment'
import 'moment/locale/es';
import { toast } from 'react-toastify'
import { storage } from "./Firebase"
import { v4 as uuidv4 } from 'uuid';

export const getDate = (d) => {
    return moment(d).format('DD/MM/YYYY')
}

export const getEasyDate = (d) => {
    let dT = moment(d).format('dddd, D MMMM YYYY')
    return dT
}

export const getAge = (d) => {
    let cur = moment()
    let dob = moment(d).format('YYYY')
    var diff = cur.diff(dob, 'years')
    return diff
}

export const isDate = (d) => {
    let dT = moment(d).isValid()
    return dT
}

export const imageUpload = async (file, refPath) => {
    if (!file) {
        alert("No file found.")
        return
    }
    const reference = ref(storage, refPath)
    const uploadTask = uploadBytesResumable(reference, file)
    let promise

    uploadTask.on(
        "state_changed",
        snapshot => { },
        error => {
            toast.error(error, {
                autoClose: 5500,
                pauseOnHover: true
            })
        },
        () => {
            promise = getDownloadURL(uploadTask.snapshot.ref)
        }
    )
    return await Promise.resolve(uploadTask)
        .then(async res =>
            await Promise.resolve(promise)
                .then(url => url)
        )
}

export const bulkImageUpload = async (images, room) => {
    if (!images || images.length === 0) {
        alert("No file found.")
        return
    }
    const result = []

    await Promise.all(images.map(async (image, idx) => {
        const imgID = uuidv4()
        let refPath = `images/rooms/${room.id}/roomImage-${imgID}`
        let url = await imageUpload(image, refPath)
        result.push({ url: url, uuid: imgID })
    }));

    return result
}


export const deleteImage = async (imgID, roomId) => {
    const imageRef = ref(storage, `images/rooms/${roomId}/roomImage-${imgID}`);
    await deleteObject(imageRef)
}

export const deleteImageBulk = async (images, roomId) => {
    await Promise.all(images.map(async (image, idx) => {
        const imgID = image.uuid
        const imageRef = ref(storage, `images/rooms/${roomId}/roomImage-${imgID}`);
        await deleteObject(imageRef)
    }));
}

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const isValidPasswordLength = (password, minLength = 8) => {
    return password.length >= minLength;
};

export const isValidPassword = (password, password2, minLength = 8) => {
    const arePasswordsEqual = password === password2;
    const isLengthValid = isValidPasswordLength(password, minLength);
    // const hasLowercase = /[a-z]/.test(password);
    // const hasUppercase = /[A-Z]/.test(password);
    // const hasDigit = /\d/.test(password);
    // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return (
        arePasswordsEqual &&
        isLengthValid
        // hasLowercase &&
        // hasUppercase &&
        // hasDigit &&
        // hasSpecialChar
    );
}

export const isAgeValid = (date) => {
    const dateOfBirth = moment(date);
    const today = moment();
    const eighteenYearsAgo = today.subtract(18, 'years');
    return dateOfBirth.isSameOrBefore(eighteenYearsAgo);
}

export const isAllFieldFilled = (data) => {
    return Object.values(data).every(campo => campo.trim() !== '');
};

export const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }