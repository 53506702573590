import React from 'react'
import styled from 'styled-components'
import HotelIMG from "../../assets/hotel.png"
import { FormButton, Image, Text } from '../../components/GlobalStyles/PageStyles'
import "./card.css"
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_NOBEDS_MIN_MAX_PRICES } from '../../graphql/queries/roomQueries'
import moment from 'moment'

const CardContainer = styled.div`
    padding: 16px;
    border-radius: 6px;
    background: white;
    box-shadow: 0 0 10px #bbbbbb;
    margin-bottom: 20px;
    display: flex;
    position: relative;
    cursor: pointer;
    :hover .action-slider{
        width: 280px;
        padding: 10px;
        border-radius: 6px;
    }

    @media(max-width: 600px) {
        flex-direction: column
    }
`

const HotelDetails = styled.div`
    padding: 0 16px;
    border-radius: 6px;
    transition: 0.5s;
    width: 100%;

    @media(max-width: 600px) {
        margin-top: 16px;
        padding: 0;
    }
`

const Card = (props) => {
    const navigate = useNavigate()
    const { data, params } = props
    const hotel = data.hotel

    const fromDate = moment(params.from).format('yyyy-MM-DDT06:00:00.000[Z]');
    const toDate = moment(params.from).add(1, 'days').format('yyyy-MM-DDT06:00:00.000[Z]');

    const { data: priceData, loading, error } = useQuery(GET_NOBEDS_MIN_MAX_PRICES, {
        variables: {
            id: hotel.id,
            from:fromDate,
            to: toDate,
        }
    })

    const searchData = {
        from: params.from,
        to: params.to,
        people: params.people,
    }

    const getStartingPrice = () => {
        if (hotel.rooms.length === 0) return "Desde - N/A"
        
        let prices = hotel.rooms.map(room => room.price)

        if (loading) {
            return "Cargando...";
        }

        let minPrice = Math.min(...prices)
        let discountPrice = minPrice - (minPrice * hotel.discount / 100)
        
        return `$${priceData?.getNobedsHotelMinMaxPrices?.min}` || `$${discountPrice} MXN`;

    }

    const getAvlRoomCount = () => {
        if (hotel.rooms.length === 0) return "No hay cuartos disponibles.";
        let roomsCount = hotel.rooms.map(room => room.roomNumbers.length).reduce((cur, res) => cur + res)
        return roomsCount
    }

    return (
        <CardContainer>
            <Image style={{ backgroundImage: `url(${hotel.image ? hotel.image : HotelIMG})`, minWidth: '260px', height: '260px' }}
                className="img-container" />
            <HotelDetails className="details">
                <Text className="clip">{hotel.name}</Text>
                <Text className="small">{hotel.location}</Text>
                <Text className="small clamp">{hotel.description}</Text>
                <Text className="small clamp">Desde: <span style={{ fontSize:'20px', color: '#f8531f' }}>{getStartingPrice()}</span></Text>
                {/* <Text className="small" style={{ marginBottom: 0 }}>{getAvlRoomCount()} Cuartos disponibles.</Text> */}
                <FormButton
                    style={{
                        width: '100%',
                        marginTop: '16px'
                    }}
                    onClick={() => navigate(`/hotel/${hotel.id}`, { state: searchData })}>Ver habitaciones</FormButton>
            </HotelDetails>
        </CardContainer>
    )
}

export default Card
