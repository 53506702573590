import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client';
import { REGISTER_USER } from '../../graphql/mutations/userMutations';
import { GlobalContext } from '../../utils/Context';
import { AuthContainer, ButtonsContainer, FormContainer } from './ModuleStyles';
import { FormButton, FormTitle, Input, InputContainer } from '../../components/GlobalStyles/FormStyles';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from '../../components/GlobalStyles/PageStyles';
import Loader from "../../components/Loaders/Loader.js"
//import DatePicker, {registerLocale} from "react-datepicker";
import { es } from "../../utils/localeDatepicker.js"
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-date-picker'
import '../../components/Utils/datepicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CalendarIcon from '../../components/Icons/CalendarIcon.js';
import { PhoneNumberInputWithNativeSelect } from 'react-phonenumber-text-input'
import { isValidEmail, isValidPassword, isAgeValid, isValidPasswordLength } from '../../utils/utilFunctions.js';
import { Checkbox, FormControlLabel, Link } from '@mui/material';
import { orange } from '@mui/material/colors';

const Register = () => {

    const { setPage } = useContext(GlobalContext)

    useEffect(() => {
        setPage('Register')
    }, [])

    const navigate = useNavigate()

    const [createUser] = useMutation(REGISTER_USER)

    const [info, setInfo] = useState({
        name: '',
        lastname: '',
        username: '',
        email: '',
        dob: null,
        password: '',
        password2: '',
        phonenumber: '',
        ppolicy: false,
        tandc: false
    })

    const [loading, setLoading] = useState(false)

    const register = (e) => {
        e.preventDefault()

        try{

        if(info.phonenumber === '' || info.phonenumber === null || info.phonenumber === undefined){
            throw new Error('Introduce tu número de teléfono.')
        }
        if(!isValidEmail(info.email)){
            throw new Error('El correo electrónico no es válido.')
        }
        if(!isValidPassword(info.password, info.password2)){
            throw new Error('Tu contraseñas no coinciden o tienen menos de 8 caracteres.')
        }
        if(!isAgeValid(info.dob)){
            throw new Error('Debes tener 18 años o más para crear una cuenta.')
        }
        if(info.tandc === false){
            throw new Error('Debes aceptar los términos y condiciones.')
        }
        if(info.ppolicy === false){
            throw new Error('Debes aceptar las politicas de privacidad.')
        }

        setLoading(true)
        createUser({
            variables: {
                name: info.name,
                lastname: info.lastname,
                username: info.username,
                email: info.email,
                password: info.password,
                dob: info.dob,
                phonenumber: info.phonenumber
            }
        })
        .then(res => {
            const user = res.data.createUser
            localStorage.setItem('user', JSON.stringify(user))
            setTimeout(() => {
                user.isManager ?
                    window.location.href = '/dashboard' :
                    window.location.href = '/'
            }, 1000);
        })
        .catch(err => {
            setLoading(false)
            toast.error(err.message, {
                autoClose: 5500,
                pauseOnHover: true
            })
        })
        
        }catch(err){
            toast.error(err.message, {
                autoClose: 5500,
                pauseOnHover: true
            })
        }
    }

    return (
        <PageContainer>
            <AuthContainer>
                {!loading ? (
                    <FormContainer>
                        <form className="form-box" onSubmit={register} autoComplete="off">
                            <FormTitle style={{ marginBottom: '20px' }}>Registrarse</FormTitle>
                            <Input style={{ margin: '10px 0' }}
                                required="true"
                                placeholder="Nombre(s)"
                                value={info.name}
                                onChange={(e) => setInfo({ ...info, name: e.target.value })}></Input>
                            <Input style={{ margin: '10px 0' }}
                                required="true"
                                placeholder="Apellido(s)"
                                value={info.lastname}
                                onChange={(e) => setInfo({ ...info, lastname: e.target.value })}></Input>
                            {/* <Input style={{ margin: '10px 0' }}
                                required="true"
                                placeholder="Usuario"
                                value={info.username}
                                onChange={(e) => setInfo({ ...info, username: e.target.value })}></Input> */}
                            <Input style={{ margin: '10px 0px'  }}
                                required="true"
                                placeholder="Correo Electrónico"
                                value={info.email}
                                onChange={(e) => setInfo({ ...info, email: e.target.value })}></Input>
                            
                            <InputContainer style={{ margin: '10px 0px 20px 0px' }}>
                                <PhoneNumberInputWithNativeSelect
                                        required
                                        value={info.phonenumber}
                                        style={{ border: '1px solid #b6b6b6', minHeight: '40px', padding: '10px 6px'}}
                                        defaultCountry='MX'
                                        onChange={(e, metadata) => {
                                            if(metadata.valid){
                                                setInfo((prevInfo) => ({
                                                    ...prevInfo,
                                                    phonenumber: metadata.fullValue
                                                }));
                                            }
                                        }}
                                />
                            </InputContainer>

                            <InputContainer style={{ margin: '10px 0px 10px 0px' }}>
                                {/* <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={info.dob}
                                    onChange={(date) => setInfo({ ...info, dob: date })}
                                    placeholder="12/21/1990"
                                    required
                                    locale={es}
                                    showMonthDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={10}
                                    scrollableYearDropdown
                                    //withPortal
                                    /> */}
                                <DatePicker 
                                    style={{ border: '1px solid #b6b6b6', minHeight: '40px', padding: '10px 6px'}}
                                    format="dd-MM-yyyy"
                                    value={info.dob}
                                    onChange={(date) => setInfo({ ...info, dob: date })}
                                />
                            </InputContainer>
                            <Input style={{ margin: '10px 0' }}
                                required="true"
                                placeholder="Contraseña"
                                type="password"
                                value={info.password}
                                onChange={(e) => setInfo({ ...info, password: e.target.value })}></Input>
                            <Input style={{ margin: '10px 0' }}
                                required="true"
                                placeholder="Confirmar Contraseña"
                                type="password"
                                value={info.password2}
                                onChange={(e) => setInfo({ ...info, password2: e.target.value })}></Input>

                            <InputContainer style={{ margin: '10px 0px 10px 0px' }}>
                                <label style={{ color: '#000' }}>Políticas y Condiciones Generales:</label>
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={info.tandc} 
                                            onChange={(e) => setInfo({ ...info, tandc: e.target.checked })} 
                                            name="tandc"
                                            sx={{
                                                color: '#000018',
                                                '&.Mui-checked': {
                                                    color: '#000024',
                                                },
                                            }}
                                            />
                                    }
                                    style={{ color: '#000', fontSize: '14px' }}
                                    label={
                                        <span>
                                            He leido y acepto los{' '}
                                            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">términos y condiciones</a>.
                                        </span>
                                    }
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={info.ppolicy} 
                                            onChange={(e) => setInfo({ ...info, ppolicy: e.target.checked })} 
                                            name="ppolicy" 
                                            sx={{
                                                color: '#000018',
                                                '&.Mui-checked': {
                                                    color: '#000024',
                                                },
                                            }}
                                            />
                                    }
                                    style={{ color: '#000', fontSize: '14px' }}
                                    label={
                                        <span>
                                            He leido y acepto la{' '}
                                            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">política de privacidad</a>.
                                        </span>
                                    }
                                />
                            </InputContainer>
                            <ButtonsContainer>
                                <FormButton style={{ border: '2px solid #000018', background: "#fff", color: "#000018" }}
                                    onClick={() => navigate('/login')}
                                >Iniciar Sesión</FormButton>
                                <FormButton type="submit">Registrarse</FormButton>
                            </ButtonsContainer>
                        </form>
                    </FormContainer>
                ) : <Loader />}
            </AuthContainer>
        </PageContainer>
    )
}

export default Register
