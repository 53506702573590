import React, { useState } from 'react'
import { FormButton, FormTitle, Input } from '../GlobalStyles/FormStyles'
import { SearchBoxContainer } from '../../components/GlobalStyles/PageStyles'
import styled from 'styled-components'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./box.css"
import { useNavigate } from 'react-router-dom';
import SelectOccupancy from './SelectOccupancy';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';
import { fontSize } from '@mui/system';
import CalendarIcon from '../Icons/CalendarIcon';
import { es } from '../../utils/localeDatepicker';
import moment from 'moment';
import '../../components/Utils/datepicker.css';

const Extras = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 12px
`

const InputContainer = styled.div`
    width: 100%;
    label{
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        display: block;
        margin-bottom: 8px
    }
`

const SearchBox = (props) => {

    const data = props.params
    const styles = props.styles

    const [checkIn, setCheckIn] = useState(data ? new Date(data.from) : new Date());
    const [checkOut, setCheckOut] = useState(data ? new Date(data.to) : new Date(new Date().setDate(new Date().getDate() + 1)));
    const [query, setQuery] = useState(data ? data.location : 'Progreso')
    const [showInput, setShowInput] = useState(false);
    const [count, setCount] = useState({
        children: data ? data.people.children : 0,
        adults: data ? data.people.adults : 0
    })

    const navigate = useNavigate()

    const handleSearch = (e) => {
        e.preventDefault()

        if(checkIn >= checkOut){
            toast.warning("La fecha de entrada no puede ser mayor o igual que la fecha de salida", {
                autoClose: 5500,
                pauseOnHover: true
            })
            return
        }

        if (count.children === 0 && count.adults === 0) {
            toast.warning("¿Cuantas personas se van a hospedar?", {
                autoClose: 5500,
                pauseOnHover: true
            })
            return
        }

        const adjustedCheckIn = new Date(checkIn);
        adjustedCheckIn.setHours(0, 0, 0, 0);
        
        const adjustedCheckOut = new Date(checkOut);
        adjustedCheckOut.setHours(0, 0, 0, 0);

        const from = adjustedCheckIn.toISOString()
        const to = adjustedCheckOut.toISOString()
        const searchData = {
            from: from,
            to: to,
            people: count,
            location: query
        }
        navigate(`/explore/${query}/${from}/${to}/${count.adults + count.children}`,
            { state: searchData })
    }


    return (
        <SearchBoxContainer style={styles}>
            <FormTitle style={{ marginBottom: '20px', color: '#000', fontSize: '20px' }}>¿Listo para elegir tu hotel ideal en <span style={{ color: '#e15050', fontSize: 'xx-large'}}>{query}</span> para hoy?</FormTitle>
            <form onSubmit={handleSearch}>
                {showInput ? (
                    <Input
                        placeholder="Busca el Hotel o Ubicación"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        required={true}
                    />
                ) : ''}
                <Extras>
                    <InputContainer>
                        <label style={{ color: '#000' }}>Entrada</label>
                        <DatePicker 
                            dateFormat="dd/MM/yyyy"
                            selected={checkIn}
                            onChange={(date) => setCheckIn(date)}
                            required
                            locale={es}
                            withPortal 
                            selectStart
                            minDate={new Date()}
                            startDate={checkIn}
                            endDate={checkOut} 
                        />
                    </InputContainer>

                    <InputContainer>
                        <label style={{ color: '#000' }}>Salida</label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={checkOut}
                            onChange={(date) => setCheckOut(date)} 
                            required
                            locale={es}
                            withPortal
                            selectEnd
                            endDate={checkOut}
                            minDate={checkIn}
                        />
                    </InputContainer>

                    <InputContainer>
                        <label style={{ color: '#000' }}>Personas</label>
                        <SelectOccupancy count={count} setCount={setCount} />
                    </InputContainer>

                    <FormButton type="submit" className='small-search-button'>
                        <SearchIcon style={{ fontSize: '22px', marginTop: '2px', marginLeft: "2px" }} />
                    </FormButton>
                </Extras>

                <FormButton type="submit" className='large-search-button'>
                    <SearchIcon style={{ fontSize: '22px', marginTop: '2px', marginLeft: "2px" }} />
                </FormButton>

            </form>
        </SearchBoxContainer>
    )
}

export default SearchBox
