import styled from 'styled-components';

const DiscountRibbon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff5c5c; /* Color del badge */
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 4px 8px; /* Espaciado dentro del badge */
    border-radius: 12px; /* Hace que sea redondeado */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra ligera */
    z-index: 10; /* Asegura que esté por encima */
`;

export default DiscountRibbon;