import {gql} from "@apollo/client";

export const GET_HOTEL = gql`
query($id: ID!){
  getHotel(id: $id){
    id
    image
    name
    description
    discount
    manager{
      name
      email
      dob
      username
    }
    rooms{
      id
      images{
        url
        uuid
      }
      name
      description
      others
      roomNobedsIDs
      occupancy
      price
      addedOn
      ratings
      roomNumbers
      hotel{
        id
        name
        location
        discount
        manager{
          id
          name
          email
        }
      }
    }
    addedOn
    location
    ratings
    totalRooms
    roomsMap
    lat
    lon
    nobedsApiKey
    phonenumber
    whatsapp
    email
  }
}
`

export const GET_HOTEL_BY_ID = gql`
query($id: ID!){
  getHotelByID(id: $id){
    id
    image
    name
    description
    discount
    manager{
      name
      email
      dob
      username
    }
    rooms{
      id
      images{
        url
        uuid
      }
      name
      description
      occupancy
      price
      addedOn
      ratings
      others
      roomNobedsIDs
      roomNumbers
      hotel{
        id
        name
        discount
      }
    }
    addedOn
    location
    ratings
    totalRooms
    roomsMap
    lat
    lon
    nobedsApiKey
    phonenumber
    whatsapp
    email
  }
}
`

export const SEARCH_HOTELS = gql`
query($location: String!, $from: Date!, $to: Date!, $occupancy: Int!){
  searchHotels(location: $location, from: $from, to: $to, occupancy: $occupancy){
    hotel{
      id
      image
      name
      description
      lat
      lon
      nobedsApiKey
      phonenumber
      whatsapp
      email
      discount
      manager{
        name
        email
        dob
        username
      }
      rooms{
        id
        images{
          url
          uuid
        }
        name
        description
        occupancy
        others
        roomNobedsIDs
        price
        addedOn
        ratings
        roomNumbers
        hotel{
          id
          name
          location
          discount
          manager{
            id
            name
            email
          }
        }
      }
      addedOn
      location
      ratings
      totalRooms
      roomsMap
    }
    rooms
  }
}
`