import {gql} from "@apollo/client";

export const GET_ROOM = gql`
query($id: ID!){
  getRoom(id: $id){
    id
    images{
      url
      uuid
    }
    name
    description
    occupancy
    others
    roomNobedsIDs
    price
    addedOn
    ratings
    hotel{
      id
      name
      location
      discount
      manager{
        id
        name
        email
      }
    }
  }
}
`

export const GET_NOBEDS_AVAILABILITY = gql`
query($id: ID!, $from: Date!, $to: Date! ){
  getNobedsRoomAvailability(id: $id, from: $from, to: $to)
}
`

export const GET_NOBEDS_MIN_MAX_PRICES = gql`
query($id: ID!, $from: Date!, $to: Date! ){
  getNobedsHotelMinMaxPrices(id: $id, from: $from, to: $to){
    min
    max
  }
}
`

export const GET_NOBEDS_ROOM_MIN_MAX_PRICES = gql`
query($id: ID!, $from: Date!, $to: Date! ){
  getNobedsRoomMinMaxPrices(id: $id, from: $from, to: $to){
    min
    max
    price
    discount
  }
}
`

export const GET_NOBEDS_ROOM_DATES_AND_PRICES = gql`
  query($id: ID!, $from: Date!, $to: Date!, $roomNumbers: [Int]!){
    getNobedsRoomDatesPrices(id: $id, from: $from, to: $to, roomNumbers: $roomNumbers){
      total
      rooms{
        room_id
        subtotal
        dates {
          date
          price,
          rid,
          room_id
        }
      }
    }
  }
`

export const GET_AVAILABLE_ROOMS = gql`
query($hotelId: ID!, $from: Date!, $to: Date!, $occupancy: Int!){
  getAvailableRooms(hotelId: $hotelId, from: $from, to: $to, occupancy: $occupancy){
    room{
      id
      images{
        url
        uuid
      }
      name
      description
      occupancy
      others
      roomNobedsIDs
      price
      addedOn
      ratings
      hotel{
        id
        name
        location
        discount
        manager{
          id
          name
          email
        }
      }
    }
    roomNumbers
  }
}
`