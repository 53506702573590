// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage, ref} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAxZilRcRh7sSExJUS_RQqbsPCd30J4lfU",
    authDomain: "rentando-f740a.firebaseapp.com",
    projectId: "rentando-f740a",
    storageBucket: "rentando-f740a.firebasestorage.app",
    messagingSenderId: "1062589676156",
    appId: "1:1062589676156:web:e853cd05cfa6c2696ae7f9",
    measurementId: "G-Y1N96Y5FXB"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app)

export {
    storage
}
