import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { LoaderBox } from '../GlobalStyles/LoaderStyles'

const PageLoader = () => {
    return (
        <LoaderBox>
            <CircularProgress size={80} style={{color: '#FF6E2A'}}/>
        </LoaderBox>
    )
}

export default PageLoader
